import Keycloak from 'keycloak-js'
import { replace } from 'lodash/fp'

import { env } from '@/env'

const {
  REACT_APP_KEYCLOAK_SUBDOMAIN,
  REACT_APP_KEYCLOAK_PATHNAME,
  REACT_APP_KEYCLOAK_CUSTOM_URL,
  REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_CLIENTID,
} = env

if (REACT_APP_KEYCLOAK_REALM === undefined) {
  throw new Error('Missing key REACT_APP_KEYCLOAK_REALM in .env')
}

if (REACT_APP_KEYCLOAK_CLIENTID === undefined) {
  throw new Error('Missing key REACT_APP_KEYCLOAK_CLIENTID in .env')
}

// Remove -lms suffix from domain when user is connecting to Masterplan through SCORM package
const host = replace('-lms.com', '.com', window.location.host)
const url =
  REACT_APP_KEYCLOAK_CUSTOM_URL ||
  `${window.location.protocol}//${REACT_APP_KEYCLOAK_SUBDOMAIN}.${host}${REACT_APP_KEYCLOAK_PATHNAME}`

const keycloak = Keycloak({
  url,
  realm: REACT_APP_KEYCLOAK_REALM,
  clientId: REACT_APP_KEYCLOAK_CLIENTID,
})

export default keycloak
